<template>
    <div>
      <b-table
        striped
        hover
        :items="listLogs"
        :fields="fields"
        :busy="isLoading"
        show-empty
        responsive
      >
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
  
        <template v-slot:cell(no)="{ index }">
          {{ (currentPageList - 1) * 10 + index + 1 }}
        </template>

        <template v-slot:cell(actions)="{ item }">
            <b-button
              size="sm"
              variant="success"
              class="btn waves-effect waves-float waves-light btn-success btn-sm"
              @click="showDetailLog(item)"
            >
                <feather-icon
                    icon="InfoIcon"
                    size="12"
                />
            </b-button>
        </template>
        
      </b-table>

      <b-modal
        id="modal-detail-log"
        centered
        title="Detail Log"
        size="lg"
        no-close-on-backdrop
        hide-footer
        >
        <div class="form">
          <div class="form-group">
            <textarea
                v-model="selectedLog"
                id="selected-log"
                class="form-control"
                cols="30"
                rows="20"
            />
            </div>
        </div>
        <b-row class="mt-2 justify-content-end">
            <b-button
                variant="primary"
                class="btn btn-sm"
                @click="copySelectedLog()"
                >
                <feather-icon icon="CopyIcon" class="mr-75" />
                {{  copyButtonText  }}
            </b-button>
        </b-row>

    </b-modal>
  
      <div
        v-if="resultData.itemsPerPage > 0"
        class="mt-3 d-flex justify-content-between align-items-center"
      >
        <small
          >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
          {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
          {{ resultData.totalItems }}</small
        >
        <b-pagination
          class="justify-content-end"
          v-model="currentPageList"
          :total-rows="resultData.totalItems"
          :per-page="resultData.itemsPerPage"
          aria-controls="my-table"
          @change="getData"
        ></b-pagination>
      </div>
    </div>
  </template>
  
  <script>
  import vSelect from "vue-select";
  import {
    configure,
  } from "vee-validate";
  import { required } from "@validations";
  import Ripple from "vue-ripple-directive";
  configure({
    classes: {
      valid: "is-valid",
      invalid: "is-invalid",
      dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
      // ...
    },
  });
  import {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BPagination,
    BDropdownItem,
    BButton,
    VBModal,
    BRow,
  } from "bootstrap-vue";
  import _ from "lodash";
  export default {
    components: {
        BTable,
        BSpinner,
        BBadge,
        BImg,
        BDropdown,
        BDropdownItem,
        BPagination,
        BButton,
        vSelect,
        BRow,
    },
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
    props: {
       logs: {
        type: "",
      },
      result: {
        type: "",
      },
      isLoading: {
        type: Boolean,
      },
      currentPage: {
        type: Number,
      },
      getData: {
        type: Function,
      },
    },
    watch: {
      isLoading(value) {
        this.isLoadingComponent = value;
      },
      logs(value) {
        this.listLogs = value;
      },
      formPayload(value) {
        this.formPayloadVersion = value;
      },
      currentPage(value) {
        this.currentPageList = value;
      },
      result(value) {
        this.resultData = value;
      }
    },
    data() {
      return {
        required,
        isLoadingComponent: true,
        currentPageList: 1,
        resultData: {},
        listLogs: [],
        fields: [
            { key: "no" },
            { key: "created_at", label: "Time Request"},
            { key: "type", label: "Status" },
            { key: "url" },
            { key: "actions" },
        ],
        selectedLog: {},
        copyButtonText: 'Copy'
      };
    },
    computed: {
      rows() {
        return this.listLogs.length;
      },
    },
    methods: {
        showDetailLog(log) { 
            this.selectedLog = JSON.stringify(log);
            this.$bvModal.show("modal-detail-log")
        },
        copySelectedLog(){
            /* Get the text field */
            const copyText = document.getElementById("selected-log");

            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            document.execCommand("copy");

            this.copyButtonText = 'Copied';
        }
    }
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  